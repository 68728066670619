import * as React from "react";
import { Route } from 'react-router-dom';

import { Admin, Resource, CustomRoutes } from 'react-admin';
import dataProvider from './Component/dataProvider'
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

import users from './users';
import applications from "./applications";
import companies from './profiles';
import UserImport from './users/UserImport';

import authProvider from "./Component/authProvider/authProvider";
import LoginPage from "./Component/authProvider/LoginPage";
import { MyAuthCallbackPage } from "./Component/authProvider/MyAuthCallbackPage";

const messages = {
  en: englishMessages
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], "en", {
  allowMissing: true
});
const App = () => (
  <Admin
    disableTelemetry
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={LoginPage}
    locale="en"  // Add this...
    i18nProvider={i18nProvider} >
    requireAuth={true}
    authCallbackPage={MyAuthCallbackPage}
    <Resource name="users" {...users} />"
    <Resource options={{ label: 'Applications' }} name="groups" {...applications} />"
    <Resource options={{ label: 'Companies' }} name="profiles" {...companies} />"
    <CustomRoutes>
      <Route path="/user/import" element={<UserImport />} />
    </CustomRoutes>
  </Admin>
);
export default App;