/* eslint-disable */

export const project_name = "awe11"; // enter all lowercase
export const project_region = "us-east-1";
export const root_domain = `${project_name}.authapp.exponent.com`;

const awsmobile = {
	project_name: `${project_name}`,
	root_domain: `${root_domain}`,
	logo_img_url: `https://${root_domain}/Logo.png`,
	aws_project_region: `${project_region}`,	// admin userpool region
	aws_user_pools_web_client_id: "22mp04lrvq2mli4mhdf4jg60od",	// admin userpool app client id
	aws_user_pools_id: "us-east-1_TumBxV7Fe",	// admin userpool id
	aws_backend_api_url: `https://api.${root_domain}`,	// backend api endpoint url
	aws_hosted_ui_domain: `${project_name}-adminportal.auth.${project_region}.amazoncognito.com`,
	app_callback_uri: "https://applion.netlify.app"
};
export default awsmobile;
