import awsmobile from '../aws-export';
import inMemoryJwt from './authProvider/inMemoryJwt';

const apiUrl = awsmobile.aws_backend_api_url;

const queriedTokens = {};
const currentPageNum = {};
const currentFiler = {};

const dataProvider = {
	//API call to get entire list of users
	//  GET users/
	getList: (resource, params) => {
		// console.log('params:', params)
		const { page, perPage } = params.pagination;

		if (currentFiler[resource] === undefined) {
			currentFiler[resource] = {}
		}

		if (queriedTokens[resource] === undefined) {
			queriedTokens[resource] = {}
		}

		// if filter changes, reset page number and clear all page tokens
		if (JSON.stringify(currentFiler[resource]) !== JSON.stringify(params.filter)) {
			// clear all page tokens
			queriedTokens[resource] = {};
		}

		const url = `${apiUrl}/${resource}?page=${page}&perPage=${perPage}&filter=${JSON.stringify(params.filter)}`;
		const pageToken = page > 1 && queriedTokens[resource][page - 1] ? queriedTokens[resource][page - 1] : null;

		console.log('url:', url);
		const token = inMemoryJwt.getToken();
		console.log ('getlist-token', token);

		if (!token) {
			return Promise.reject(new Error('No token'));
		}
		return fetch(url, {
			method: 'POST',
			body: pageToken,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(res => res.json())
			.then(json => {
				console.log ('getlist json', json)
				if (json.type === 'exception' || json.type === 'Error') {
					throw new Error(json.message);
				}
				currentPageNum[resource] = page;
				currentFiler[resource] = params.filter;
				if (queriedTokens[resource] === undefined) {
					queriedTokens[resource] = {}
				}
				queriedTokens[resource][page] = json.PaginationToken;

				return ({
					data: json.data,
					pageInfo: {
						hasNextPage: json.PaginationToken ? true : false,
						hasPreviousPage: page > 1,
					},
				})
			})
	},
	getOne: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;
		return fetch(url, {
			headers: {
				Authorization: inMemoryJwt.getToken(),
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		})
			.then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	delete: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;
		return fetch(url, {
			method: 'DELETE',
			headers: {
				Authorization: inMemoryJwt.getToken(),
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		})
			.then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	deleteOne: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;
		return fetch(url, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: inMemoryJwt.getToken(),
			}
		})
			.then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	deleteMany: (resource, params) => {
		const url = `${apiUrl}/${resource}`;
		return fetch(url, {
			method: 'DELETE',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: inMemoryJwt.getToken(),
			}
		}).then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	update: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;
		return fetch(url, {
			method: 'PUT',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: inMemoryJwt.getToken(),
			}
		}).then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	updateOne: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;
		return fetch(url, {
			method: 'PUT',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: inMemoryJwt.getToken(),
			}
		}).then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	updateMany: (resource, params) => {
		const url = `${apiUrl}/${resource}`;
		return fetch(url, {
			method: 'PUT',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: inMemoryJwt.getToken(),
			}
		}).then(res => res.json())
			.then(json => ({ data: json.data }))
	},
	create: (resource, params) => {
		const url = `${apiUrl}/${resource}`;
		return fetch(url, {
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: inMemoryJwt.getToken(),
			}
		}).then(res => {
			console.log('res:', res)
			return res.json()
		})
			.then(json => {
				console.log('json:', json)
				if (json.type === 'exception' || json.type === 'Error') {
					throw new Error(json.message)
				}
				else {
					return ({ data: json.data })
				}
			})
	}
}

export default dataProvider;