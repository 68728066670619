const required = (message = 'Required') =>
	value => value ? undefined : message;
const minLength = (min, message = 'Password must be at least 8 characters') =>
	value => value && value.length <= min ? message : undefined;
const hasLowercase = () =>
	value => value && !/[a-z]/.test(value) ? 'Password must contain at least one lowercase letter' : undefined;
const hasUppercase = () =>
	value => value && !/[A-Z]/.test(value) ? 'Password must contain at least one uppercase letter' : undefined;
const hasNumber = () =>
	value => value && !/[0-9]/.test(value) ? 'Password must contain at least one number' : undefined;
const hasSpecial = () =>
	value => value && !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) ? 'Password must contain at least one special character' : undefined;
export const validatePassword = [required(), minLength(8), hasNumber(), hasUppercase(), hasLowercase(), hasSpecial()];

const isIDN = () =>
	value => value && !/^\+(?:[0-9] ?){6,14}[0-9]$/.test(value) ? 'phone number must start with a plus (+) sign, followed immediately by the country code, eg. +111111111' : undefined;
export const validatePhoneNumber = [isIDN()];