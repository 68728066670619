import * as React from "react";
import { SimpleShowLayout, TextField, Edit, SimpleForm, TextInput, DateField } from "react-admin";

export const ProfileEdit = () => (
	<Edit mutationMode="optimistic" redirect="show">
		<SimpleForm>
			<SimpleShowLayout>
				<TextField label="Company" source="name" />
				<DateField source="CreationDate" />
				<DateField source="LastModifiedDate" />
			</SimpleShowLayout>
			<TextInput source="Description" fullWidth />
		</SimpleForm>
	</Edit >
)