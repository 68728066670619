import {
    EditButton, Button, List, Datagrid,
    EmailField, ArrayField, SingleFieldList, FunctionField,
    TextField, ChipField, useRedirect, TextInput, CreateButton, TopToolbar, FilterButton, useGetList, SelectInput
} from 'react-admin';
import { InputAdornment, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';

const ListActions = () => {
    const redirect = useRedirect();
    const handleClick = () => {
        redirect('/user/import');
    }

    return (
        <TopToolbar>
            <FilterButton filters={usersFilter} />
            <CreateButton label='Invite User' />
            <Button
                onClick={handleClick}
                label="Import"
            >
                <PublishIcon />
            </Button>
        </TopToolbar>
    );
}

const usersFilter = (groupChoices) => ([
    // <SearchInput source="email" alwaysOn />,
    <TextInput
        label="Search Users with email"
        source="email"
        // alwaysOn
        InputProps={{
            endAdornment: (
                <InputAdornment>
                    <IconButton>
                        <Search />
                    </IconButton>
                </InputAdornment>
            )
        }}
    />,
    <SelectInput
        outlined label="Application" source="groups" choices={groupChoices} />,

]);

export const UserList = props => {
    const { data } = useGetList(
        'groups',
        {
            pagination: { page: 1, perPage: 60 },
            sort: { field: 'createdAt', order: 'DESC' }
        }
    );

    const groupChoices = data ? data.map(item => ({ id: item.id, name: item.group })) : [];

    return (
        <List  {...props}
            filters={usersFilter(groupChoices)}
            perPage={10}
            // pagination={<UserPagination />}
            exporter={false}
            bulkActionButtons={false}
            actions={<ListActions />}
        >
            <Datagrid rowClick="show" bulkActionButtons={false} optimized>
                {/* <TextField source="username" sortable={false} /> */}
                <EmailField source="email" sortable={false} onClick={() => { }} />
                <TextField source="phone_number" sortable={false} />
                <FunctionField label="Full Name"
                    render={record => `${record.given_name ? record.given_name : ''} ${record.family_name ? record.family_name : ''}`} />
                <TextField label="Company" source='profile' sortable={false}  emptyText='---'/>
                <TextField label="City" source="locale" sortable={false} />
                <ArrayField label="Application" source="groups">
                    <SingleFieldList>
                        <FunctionField render={(record) => (
                            <ChipField record={{ name: record }} source="name" />
                        )} />
                    </SingleFieldList>
                </ArrayField>
                <EditButton />
            </Datagrid>
        </List>
    )
};