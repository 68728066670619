import { ProfileCreate } from "./ProfileCreate";
import { ProfileEdit } from "./ProfileEdit";
import { ProfileList } from "./ProfileList";
import { ProfileShow } from "./ProfileShow";
import BusinessIcon from '@mui/icons-material/Business';

const profiles ={
  show: ProfileShow,
  list: ProfileList,
  edit: ProfileEdit,
  create: ProfileCreate,
  hasCreate: true,
  icon: BusinessIcon,
  recordRepresentation: "model",
};

export default profiles;