import { AccountCircle } from "@mui/icons-material";
import { Avatar, Container, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import * as React from "react";
import {
  useGetList,
  Edit, TextInput, AutocompleteArrayInput, SelectInput,
  BooleanInput, FunctionField, Form, EmailField, SaveButton, DeleteButton, TopToolbar, ShowButton
} from "react-admin";

import { validatePhoneNumber } from "../utils/validation";

export const UserEdit = () => {
  const { data } = useGetList(
    'groups',
    {
      pagination: { page: 1, perPage: 60 },
      sort: { field: 'createdAt', order: 'DESC' }
    }
  );

  const profile = useGetList(
    'profiles',
    {
      pagination: { page: 1, perPage: 60 },
      sort: { field: 'createdAt', order: 'DESC' }
    }
  );

  const EditActions = () => (
    <TopToolbar>
      <DeleteButton confirmTitle="Are you sure you want to delete this user?" confirmContent="" />
      <ShowButton />
    </TopToolbar>
  );

  const groupChoices = data ? data.map(item => ({ id: item.id, name: item.group })) : [];
  const profileChoices = profile && profile.data ? profile.data.map(item => ({ id: item.id, name: item.name })) : [];
  console.log('user edit profileData ', profile, 'profileChoices ', profileChoices)

  return (
    <>
      <Edit mutationMode="optimistic" redirect="show" actions={<EditActions />}>
        <Container style={{ padding: '15px' }}>
          <div style={{
            margin: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <FunctionField render={record =>
              <h1>{`Update ${(record.given_name ? `- ${record.given_name.toUpperCase()}` : 'Profile')}`}</h1>} />
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={5}>
                  <List >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircle />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="EMAIL : " secondary={<><EmailField label={null} source="email" /></>} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={0} sm={6} md={6} lg={7} />
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <TextInput
                    variant="outlined" fullWidth
                    label="First Name"
                    source="given_name"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <TextInput
                    variant="outlined" fullWidth
                    label="Last Name"
                    source="family_name"
                    required
                  />
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} />
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <TextInput
                    variant="outlined" fullWidth
                    label="City" source="locale"
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <AutocompleteArrayInput label="Application" source="groups" choices={groupChoices} variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} />
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <TextInput
                    variant="outlined" fullWidth
                    source="phone_number"
                    validate={validatePhoneNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <SelectInput label="Company" source="profile" choices={profileChoices} variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} />
                <Grid item xs={12} sm={5} md={5} lg={5}>

                  <FunctionField render={record => (
                    <BooleanInput label="Enable SMS MFA" source="sms_mfa_enabled" disabled={!record.phone_number_verified} />)
                  } />
                </Grid>
              </Grid>
              <Grid container justify="flex-end">
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <SaveButton
                    label="Update"
                  />
                </Grid>
              </Grid>
            </Form>
          </div>
        </Container>
      </Edit >
    </>
  )
}