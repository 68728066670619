import * as React from "react";
import { SimpleShowLayout, TextField, Edit, SimpleForm, TextInput, DateField } from "react-admin";

export const ApplicationEdit = () => (
	<Edit mutationMode="optimistic" redirect="show">
		<SimpleForm>
			<SimpleShowLayout>
				<TextField label="Application" source="group" />
				<DateField source="creationDate" />
				<DateField source="lastModifiedDate" />
			</SimpleShowLayout>
			<TextInput source="description" fullWidth />
		</SimpleForm>
	</Edit >
)