
import getProfileFromToken from "./getProfileFromToken";

import awsExports from "../../aws-export";
import inMemoryJwt from "./inMemoryJwt";
import { Auth } from '@aws-amplify/auth';
import App from "../../App";

const provider = `Exponent-${awsExports.project_name}-Admin`;
const authOptions = {
  region: `${awsExports.aws_project_region}`,
  redirectUri: `${window.location.protocol}//${window.location.host}`,
  userPoolId: `${awsExports.aws_user_pools_id}`,
  userPoolWebClientId: `${awsExports.aws_user_pools_web_client_id}`,
  oauth: {
    domain: `${awsExports.aws_hosted_ui_domain}`,
    scope: [ 'email', 'openid', 'profile'],
    redirectSignIn: `${window.location.protocol}//${window.location.host}`,
    redirectSignOut: `${window.location.protocol}//${window.location.host}`,
    responseType: 'code'
  }
}

Auth.configure(authOptions);

const cleanup = () => {
  // Remove the ?code&state from the URL
  window.history.replaceState(
    {},
    window.document.title,
    window.location.origin
  );
};

const authProvider = {
  login: async () => {
    // 1. Redirect to the issuer to ask authentication
    //await userManager.signinRedirect();
    await Auth.federatedSignIn({
      provider: provider,
      options: authOptions
    });
    return Promise.resolve(); // Do not return anything, the login is still loading
  },
  logout: async () => {
    console.log('logout')
    Auth._storage.removeItem('accessToken');
    // Remove the token from the local storage
    if (inMemoryJwt.getToken()) {
      inMemoryJwt.ereaseToken()
      // Redirect to the issuer to ask logout
      Auth.signOut({ provider });
    }
  },
  checkError: () => {
    console.log('checkError')
    // localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = inMemoryJwt.getToken();

    if (!token) {
      return Promise.reject({ message: false }); // adding message false hides the red "press login to continue message"
    }
    console.log('token')
    // This is specific to the Google authentication implementation
    const jwt = getProfileFromToken(token);
    const now = new Date();
    console.log('jwt', jwt)
    console.log('jwt expired', now.getTime() > jwt.exp * 1000)

    return now.getTime() > jwt.exp * 1000
      ? Promise.reject()
      : Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    const token = inMemoryJwt.getToken();
    const profile = getProfileFromToken(token);

    return Promise.resolve({
      id: profile.sub,
      fullName: profile.name,
      avatar: profile.picture,
    });
  },
  handleCallback: async () => {
    // We came back from the issuer with ?code infos in query params
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    console.log('code', code, 'state', state)

    if (!code || !state) {
      return Promise.resolve()
    }

    // get the token and add to memory
    const session = await Auth.currentSession();
    if (session.accessToken.jwtToken) {
      const token = session.getAccessToken().getJwtToken();
      if (token !== Auth._storage.getItem('accessToken')) {
        console.log('get token', token)
        inMemoryJwt.setToken(token);
      }

    }

    cleanup();
    return Promise.resolve();
  },
};

export default authProvider;