import * as React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const ProfileCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput label="Company Name" source="name" fullWidth required />
			<TextInput source="Description" fullWidth />
		</SimpleForm>
	</Create>
);
