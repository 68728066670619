import * as React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const ApplicationCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput label="Application Name" source="group" fullWidth required />
			<TextInput source="description" fullWidth />
		</SimpleForm>
	</Create>
);
