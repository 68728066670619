import {
    useList,
    ListContextProvider,
    Datagrid,
    EmailField,
    TextField,
    ArrayField,
    ChipField,
    FunctionField,
    SingleFieldList,
    BooleanField,
    useGetList
} from 'react-admin';

import { GppBad } from '@mui/icons-material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { red, yellow } from '@mui/material/colors';
import { Typography, CircularProgress } from '@mui/material';

export const UserImportList = (props) => {
    console.log('import props', props)
    const listContext = useList({ data: props.data });
    const mailformat = /^\b[A-Z0-9._+%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    const { data, isLoading } = useGetList(
        'profiles',
        {
            pagination: { page: 1, perPage: 60 },
        });

    if (isLoading) {
        return < CircularProgress />
    }
    return (
        <ListContextProvider value={listContext}  >
            <Datagrid bulkActionButtons={false} optimized>
                <FunctionField label="Email" render={(record) =>
                    (record.email && record.email.trim() !== '' && record.email.match(mailformat)) ?
                        <EmailField record={{ name: record.email }} source="name" /> :
                        <Typography variant="body2" style={{ backgroundColor: '#f1f1f1' }}>
                            {record.email} <br /> <div style={{ color: '#ff0000' }}> Invalid Email </div>
                        </Typography>
                } />
                <FunctionField label="First Name" render={(record) =>
                    (record.given_name && record.given_name.trim() !== '') ?
                        <TextField record={{ name: record.given_name }} source="name" /> :
                        <TextField record={{ name: '<Required>' }} source="name" sx={{ backgroundColor: '#f1f1f1', color: red[500] }} />
                } />
                <FunctionField label="Last Name" render={(record) =>
                    (record.family_name && record.family_name.trim() !== '') ?
                        <TextField record={{ name: record.family_name }} source="name" /> :
                        <TextField record={{ name: '<Required>' }} source="name" sx={{ backgroundColor: '#f1f1f1', color: red[500] }} />

                } />
                <FunctionField label="Phone Number" render={(record) =>
                    (record.phone_number && record.phone_number.trim() !== '' & record.phone_number[0] !== '+') ?
                        <Typography variant="body2" style={{ backgroundColor: '#f1f1f1' }}>
                            {record.phone_number} <br /> <div style={{ color: '#ff0000' }}> Need start with "+" </div>
                        </Typography> :
                        <TextField record={{ name: record.phone_number }} source="name" />
                } />
                <BooleanField source="sms_mfa_enabled"
                    TrueIcon={VerifiedIcon} FalseIcon={GppBad} />
                <FunctionField label="Company" render={(record) =>
                    (record.profile && record.profile.trim() !== '') ?
                        data.find(profile => profile.id.toLowerCase() === record.profile.trim().toLowerCase()) ?
                            <TextField record={{ name: record.profile }} source="name" /> :
                            <TextField record={{ name: record.profile }} source="name" sx={{ backgroundColor: yellow[500] }} /> :
                        <TextField record={{ name: '<Required>' }} source="name" sx={{ backgroundColor: '#f1f1f1', color: red[500] }} />
                } />
                <FunctionField label="Application" render={(record) =>
                    (record.groups && record.groups.length > 0) ?
                        <ArrayField label="Application" source="groups">
                            <SingleFieldList>
                                <FunctionField render={(record) => (
                                    <ChipField record={{ name: record }} source="name" variant="outlined" />
                                )} />
                            </SingleFieldList>
                        </ArrayField> :
                        <TextField record={{ name: '<Required>' }} source="name" sx={{ color: red[500] }} />
                } />
            </Datagrid>
            <Typography variant="body2" style={{ paddingTop: '20px' }}>
                * Companies highlighted in <span style={{ backgroundColor: yellow[500] }}>yellow</span> do not currently exist in the system and will be created upon import.
            </Typography>
        </ListContextProvider>
    );
};