import { Show, SimpleShowLayout, DateField, TextField, } from 'react-admin';

export const ProfileShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField label="Company" source="name" />
            <TextField source="Description" />
            <DateField source="CreationDate" />
			<DateField source="LastModifiedDate" />
        </SimpleShowLayout>
    </Show>
);